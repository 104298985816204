import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about1 from "../../assets/images/about-kwec-logo2.png";
export default class HomeAbout extends Component {
  constructor() {
    super();
    this.state = {
      startCounter: false,
    };
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="about-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <h2 className="block-title__title">About us</h2>
                </div>
                <p className="about-two__text">
                  Kids World Education Consultants, has been transforming the
                  landscape in the field of early childhood education since
                  2012. With collective experience of nearly 25 years, we have
                  excelled in implementing pre-primary education programmes all
                  over India and beyond.
                  <br></br>
                  We offer innovative, age-appropriate and theme-based products
                  as well as services to engage with young learners thereby,
                  helping them become super-achievers in the future!
                </p>
                <a href="/about" className="thm-btn">
                  Know more about us
                </a>
              </div>
            </div>
            <div className="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
              <div>
                <img className="about-two__image" src={about1} alt="" />
                <div className="about-two__count">
                  <div className="about-two__count-text">
                    Children <br />
                    Benefited
                    <span className="counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 2500 : 0} />
                      </VisibilitySensor>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
