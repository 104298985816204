import React from "react";
import circleStripe from "../../assets/images/circle-stripe-1.png";
import lineStripe from "../../assets/images/line-stripe-1.png";
import gallery1 from "../../assets/images/workshops/children1.jpg";
import gallery2 from "../../assets/images/workshops/children2.jpg";
import gallery3 from "../../assets/images/workshops/teacher1.jpg";
import gallery4 from "../../assets/images/workshops/teacher2.jpg";
import { useState } from "react";

import { Modal, Button } from "react-bootstrap";

const HomeWorkshops = () => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="cta-four pb-0">
      <div className="container text-center">
        <div className="block-title">
          <h2 className="block-title__title text-white">Upcoming workshops</h2>
          <section className="gallery-one">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="gallery-one__single" onClick={handleShow}>
                    <img src={gallery1} alt="" className="rounded"/>
                    <a className="gallery-one__popup img-popup">
                      <Button className="text-white bg-transparent border-0 btn-outline-light btn-link">
                        Enquiry
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="gallery-one__single" onClick={handleShow}>
                    <img src={gallery2} alt="" className="rounded"/>
                    <a className="gallery-one__popup img-popup">
                      <Button className="text-white bg-transparent border-0 btn-outline-light btn-link">
                        Enquiry
                      </Button>
                    </a>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-6">
                  <div className="gallery-one__single" onClick={handleShow}>
                    <img src={gallery3} alt="" className="rounded"/>
                    <a className="gallery-one__popup img-popup">
                      <Button className="text-white bg-transparent border-0 btn-outline-light btn-link">
                        Enquiry
                      </Button>
                    </a>
                  </div>
                </div>{" "}
                <div className="col-lg-3 col-md-6">
                  <div className="gallery-one__single" onClick={handleShow}>
                    <img src={gallery4} alt="" className="rounded"/>
                    <a className="gallery-one__popup img-popup">
                      <Button className="text-white bg-transparent border-0 btn-outline-light btn-link">
                        Enquiry
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Form</Modal.Title>
        </Modal.Header>

        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default HomeWorkshops;
