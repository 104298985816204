import React from "react";
import Swiper from "react-id-swiper";
import brand1 from "../../assets/images/partners/dewan.png";
import brand2 from "../../assets/images/partners/little.png";
import brand3 from "../../assets/images/partners/pinetree.png";
import brand4 from "../../assets/images/partners/akola.png";
import brand5 from "../../assets/images/partners/kids.png";
import brand6 from "../../assets/images/partners/educate.png";
import brand7 from "../../assets/images/partners/banyan.png";
import "swiper/css/swiper.css";
const HomeAssociations = () => {
  const params = {
    slidesPerView: 3,
    loop: true,
    speed: 500,
    spaceBetween: 10,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 4
      },
      640: {
        slidesPerView: 3
      },
      320: {
        slidesPerView: 2
      }
    }
  };

  return (
    <section className="brand-two ">
      <div className="container">
        <div className="block-title">
          <h2 className="block-title__title">Our Valued Associations</h2>
        </div>
        <div className="brand-one__carousel">
          <Swiper {...params}>
            <div className="item">
              <img src={brand1} alt="" />
            </div>
            <div className="item">
              <img src={brand2} alt="" />
            </div>
            <div className="item">
              <img src={brand3} alt="" />
            </div>
          
            <div className="item">
              <img src={brand5} alt="" />
            </div>
           
            <div className="item">
              <img src={brand4} alt="" />
            </div>
            <div className="item">
              <img src={brand6} alt="" />
            </div>
            <div className="item">
              <img src={brand7} alt="" />
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default HomeAssociations;
