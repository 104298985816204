import React from "react";
const HomeProjects = () => {
  return (
    <section className="cta-five">
      <div className="container text-center">
        <h2 className="cta-five__title">
          We're <strong className="text-info">Global!</strong>
        </h2>
        <p className="cta-five__text">
          Our domestic success and diverse experience in the field gave us
          opportunities to conduct workshops abroad too. With the goal to
          provide quality early childhood education we undertook the following
          projects beyond national borders.
          <br />
          <br />
          <ul className="text-left">
            <li>
              <strong className="font-bold text-info">Baku, Azerbaijan</strong>{" "}
              – Jolly Phonics and Jolly Grammar Teachers’ Training in
              collaboration with Dynamic Education
            </li>
            <li>
              <strong className="font-bold text-info">Thimphu, Bhutan</strong> –
              Jolly Phonics Teachers’ Training in collaboration with Phensem
              Parents Support Group
            </li>
            <li>
              <strong className="font-bold text-info">Slovakia</strong> - Jolly
              Grammar training session for teachers of 46 Trial Schools
            </li>
            <li>
              <strong className="font-bold text-info">Slovakia</strong> - The
              workshop 'Developmental Aspects in Early Years Education' is
              scheduled on 28th January 2021
            </li>
          </ul>
        </p>
        <a
          href="/projects"
          className="thm-btn cta-five__btn bg-info text-white"
        >
          more projects
        </a>
      </div>
    </section>
  );
};

export default HomeProjects;
