import React, { Component } from "react";
import strech from "../../assets/images/scratch-1-1.png";
import recapvideo from "../../assets/video/2022-review.mp4";
import ModalVideo from "react-modal-video";
export default class HomeVideo extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <section className="video-two" style={{backgroundColor: "red"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="video-two__content">
                <h2 className="video-two__title">2022 Recap</h2>
                <video width="100%" height="100%" controls>
                    <source src={recapvideo} type="video/mp4" />
                  </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
