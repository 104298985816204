import React from "react";
import { Link } from "gatsby";

const HomeHero = () => {
  return (
    <div className="banner-wrapper">
      <section className="banner-one banner-carousel__one no-dots">
        <div className="banner-one__slide banner-one__slide-one">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <h3 className="banner-one__title banner-one__light-color animate-gradient-text">
                  Kids World Education Consultants
                </h3>
                <p className="banner-one__tag-line">
                  we'll teach your child to read , learn & speak the right way!
                </p>
                <div className="col-md-12 text-center">
                  <Link to="/courses" className="thm-btn rounded banner-course">
                    Explore Courses
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HomeHero;
