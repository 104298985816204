import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import HomeHero from "../components/Home/HomeHero";
import HomeAbout from "../components/Home/HomeAbout";
import HomeCourses from "../components/Home/HomeCourses";
import HomeVideo from "../components/Home/HomeVideo";
import HomeRecap from "../components/Home/HomeRecap";
import HomeProjects from "../components/Home/HomeProjects";
import HomeWorkshops from "../components/Home/HomeWorkshops";
import HomeTestimonials from "../components/Home/HomeTestimonials";
import HomeAssociations from "../components/Home/HomeAssociations";
import Footer from "../components/Footer";
const HomePage = () => (
  <Layout pageTitle="KWEC · Home">
    <Navbar />
    
    <HomeHero />
   
    <HomeAbout />
    {/* <HomeRecap /> */}
    <HomeCourses />
    <HomeVideo />
    <HomeProjects />
    <HomeWorkshops />
    <HomeTestimonials />
    <HomeAssociations />
    <Footer />
  </Layout>
);

export default HomePage;
