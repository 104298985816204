import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import course1 from "../../assets/images/courses/preschool.jpg";
import course2 from "../../assets/images/courses/precshool-curr.jpg";
import course3 from "../../assets/images/courses/jolly.jpg";
import course4 from "../../assets/images/courses/edusmarty.jpg";
import course5 from "../../assets/images/courses/hwt.jpg";
import course6 from "../../assets/images/courses/ntt2-01.jpg";
import course7 from "../../assets/images/courses/one.jpg";
const HomeCourses = () => {
  const params = {
    slidesPerView: 3,
    loop: true,
    speed: 1500,
    spaceBetween: 30,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  const data = {
    title: "Preschool needs",
    description:
      "Anagha holds a master’s degree in Science from The University of Pune (Maharashtra) and has aced distance learning courses conducted by World Intellectual Property Organization (WIPO) with A+ grades. She is a Post Graduate in Scientific & Technical Writing and a diploma holder in Computer Science. Anagha has three research papers published in international journals, to her credit. Her fellowship gave her the opportunity to present a paper at Shailesh J Mehta School of Management (SJMSOM), IIT Bombay and was conferred with the Best Paper Award at that conference. Also, she has rich experience in counseling parents in the field of early childhood education. Anagha is an accredited Professional Trainer from Jolly Learning Ltd. (UK) for Jolly Phonics & Jolly Grammar. She has attended 6 hours workshop of ‘Brain Gym – Hooking Up from Head to Toe’. She has been conducting various classes and workshops, including handwriting improvement programmes, for children, parents as well as teachers in various schools, for more than 8 years. She also works closely with a school for under-privileged children. ",
  };

  return (
    <div>
      <section className="course-one__top-title home-one">
        <div className="container">
          <div className="block-title mb-0">
            <h2 className="block-title__title">
              Explore our <br />
              popular courses
            </h2>
          </div>
        </div>
        <div className="course-one__top-title__curve"></div>
      </section>
      <section className="course-one course-one__teacher-details home-one">
        <div className="container">
          <div className="course-one__carousel">
            <Swiper {...params}>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course1} alt="" />
                  </div>
                  <div className="course-one__content">
                    <a href="/courses/preschool-needs" className="course-one__category">
                      for Schools
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/preschool-needs">Preschool needs</a>
                    </h2>
                    <br/>
                    <p>Looking for assistance to establish a new preschool or revamp your existing preschool?</p>
                    <a href="/courses/preschool-needs" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course2} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for schools
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/preschool-curriculum">Preschool Curriculum</a>
                    </h2>
                    <br/>
                    <p>Essential subjects critical to the development of young children with innovative teaching methods</p>
                    <a href="/courses/preschool-curriculum" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course3} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for young learners & Teachers
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/jolly">Jolly Phonics & Grammar</a>
                    </h2>
                    <p>Fun and child centred approach to teaching literacy through synthetic phonics.</p>


                    <a href="/courses/jolly" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course4} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for young learners
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/edusmarty">EduSmarty Student Kit</a>
                    </h2>
                    <br/>
                    <p>A comprehensive kit to provide your child with the best early childhood education</p>


                    <a href="/courses/edusmarty" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course5} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for young learners
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/handwriting">Handwriting program</a>
                    </h2>
                    <br/>
                    <p>A revolutionary method to facilitate handwriting skills backed by three decades of research.</p>


                    <a href="/courses/handwriting" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course6} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for Aspiring Teachers
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/teacher-training">Nursery Teaching Program</a>
                    </h2>
                    <p> Become a certified Early Childhood Educator and start your career as a qualified teacher. </p>


                    <a href="/courses/teacher-training" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="course-one__single color-1">
                  <div className="course-one__image">
                    <img src={course7} alt="" />
                    
                  </div>
                  <div className="course-one__content">
                    <a href="#none" className="course-one__category">
                      for teachers
                    </a>
                    <h2 className="course-one__title">
                      <a href="/courses/professional-development">Professional Development</a>
                    </h2>
                    <br/>
                    <p>Get a competitive edge in the arena of early childhood education.</p>


                    <a href="/courses/professional-development" className="course-one__link">
                      Know more
                    </a>
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HomeCourses;
